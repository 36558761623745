
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../images/logo-full.png';
import axios from "axios";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import firebase_app from "../../firebase";

const auth = getAuth(firebase_app);

const Login = () => {
   const [loginData, setLoginData] = useState({});
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState('');
   const navigate = useNavigate();

   useEffect(() => {
      const resolveLogin = async () => {

         if (!localStorage.getItem('userData')) { return; }

         const user = JSON.parse(localStorage.getItem('userData'));

         var response = await axios
            .get(`http://127.0.0.1:8090/api/auth/v1/validToken?token=${user.stsTokenManager.accessToken}`);

         if (!response.data) { return; }

         navigate('/home');
      };
      resolveLogin();
   }, []);

   const submitHandler = async () => {
      setError('');
      if (!email || !password) {
         setError('Please fill in both email and password');
         return;
      }

      try {
         var response = await signInWithEmailAndPassword(auth, email, password);

         console.log(response);

         localStorage.setItem('userData', JSON.stringify(response.user));

         navigate('/home');

      } catch (error) {
         setError('Invalid credentials. Please try again.');
         console.error('Login error:', error);
      }
   };


   return (
      <div className="row justify-content-center h-100 align-items-center h-80">
         <div className="col-md-6">
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <div className="text-center mb-3">
                           <Link to={"/"}><img src={logo} alt="" style={{ maxWidth: '200px' }} /></Link>
                           <div className="text-center mb-4 text-white"> Centralize Your Data and Workflows, Operate More Efficiently.  </div>

                        </div>
                        <h4 className="text-center mb-4 text-white"> Sign in to your account  </h4>
                        <form action="" onSubmit={(e) => e.preventDefault(submitHandler)}  >
                           <div className="form-group">
                              <label className="mb-1 text-white">  <strong>Email</strong> </label>
                              <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-white "> <strong>Password</strong>  </label>
                              <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                           </div>
                           <div className="form-row d-flex justify-content-between mt-4 mb-2">
                              <div className="form-group">
                                 <div className="custom-control custom-checkbox ml-1 ">
                                    <input type="checkbox" className="custom-control-input" id="basic_checkbox_1" />
                                    <label className="custom-control-label text-white" htmlFor="basic_checkbox_1"  >  Remember my preference </label>
                                 </div>
                              </div>
                              <div className="form-group">
                                 <Link className="text-white" to="/page-forgot-password"  > Forgot Password?  </Link>
                              </div>
                           </div>
                           <div className="text-center">
                              <button type="submit" className="btn bg-white text-success btn-block" onClick={submitHandler} > Sign In </button>
                           </div>
                        </form>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="new-account mt-3">
                           <p className="text-white"> Don't have an account?{" "}   <Link className="text-white" to="/page-register">  Sign up   </Link>  </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
