//import React from "react";

import React, { useState } from "react";

/// React router dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ForgotPassword from "./pages/ForgotPassword";
/// Widget
import Widget from "./pages/Widget";

/// Deshboard
import Home from "./components/Dashboard/Home/Home";
import Messages from "./components/Dashboard/Messages/Messages";
import Contacts from "./components/Dashboard/Contacts/Contacts";
import Kanban from "./components/Dashboard/Kanban/Kanban";
import Projects from "./components/Dashboard/Projects/Projects";
import Calendarpage from "./components/Dashboard/Calendarpage/Calendarpage";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Chart
import SparklineChart from "./components/charts/Sparkline";
import ChartFloat from "./components/charts/chartflot/index";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import BtcChart from "./components/charts/apexcharts/ApexChart";
import ApexChart from "./components/charts/apexcharts";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pulgin
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

const Markup = () => {

	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];
	let pagePath = path.split("-").includes("page") || path.length < 1;


	const [activeEvent, setActiveEvent] = useState(!path);

	const routes = [

		/// pages
		{ url: "", component: Registration },

		/// Dashboard
		// { url: "", component: Home },
		{ url: "home", component: Home },
		{ url: "projects", component: Projects },
		{ url: "contacts", component: Contacts },
		{ url: "kanban", component: Kanban },
		{ url: "calendar", component: Calendarpage },
		{ url: "messages", component: Messages },
		
		/// Bootstrap
		{ url: "ui-alert", component: UiAlert },
		{ url: "ui-badge", component: UiBadge },
		{ url: "ui-button", component: UiButton },
		{ url: "ui-modal", component: UiModal },
		{ url: "ui-button-group", component: UiButtonGroup },
		{ url: "ui-accordion", component: UiAccordion },
		{ url: "ui-list-group", component: UiListGroup },
		{ url: "ui-media-object", component: UiMediaObject },
		{ url: "ui-card", component: UiCards },
		{ url: "ui-carousel", component: UiCarousel },
		{ url: "ui-dropdown", component: UiDropDown },
		{ url: "ui-popover", component: UiPopOver },
		{ url: "ui-progressbar", component: UiProgressBar },
		{ url: "ui-tab", component: UiTab },
		{ url: "ui-pagination", component: UiPagination },
		{ url: "ui-typography", component: UiTypography },
		{ url: "ui-grid", component: UiGrid },
		/// Apps
		{ url: "app-profile", component: AppProfile },
		{ url: "post-details", component: PostDetails },
		{ url: "email-compose", component: Compose },
		{ url: "email-inbox", component: Inbox },
		{ url: "email-read", component: Read },
		{ url: "app-calender", component: Calendar },
		/// Shop
		{ url: "ecom-product-grid", component: ProductGrid },
		{ url: "ecom-product-list", component: ProductList },
		{ url: "ecom-product-detail", component: ProductDetail },
		{ url: "ecom-product-order", component: ProductOrder },
		{ url: "ecom-checkout", component: Checkout },
		{ url: "ecom-invoice", component: Invoice },
		{ url: "ecom-product-detail", component: ProductDetail },
		{ url: "ecom-customers", component: Customers },
	
		/// Chart
		{ url: "chart-sparkline", component: SparklineChart },
		{ url: "chart-float", component: ChartFloat },
		{ url: "chart-chartjs", component: ChartJs },
		{ url: "chart-chartist", component: Chartist },
		{ url: "chart-btc", component: BtcChart },
		{ url: "chart-apexchart", component: ApexChart },
		{ url: "chart-rechart", component: RechartJs },
	
		/// table
		{ url: 'table-filtering', component: FilteringTable },
		{ url: 'table-sorting', component: SortingTable },
		{ url: "table-datatable-basic", component: DataTable },
		{ url: "table-bootstrap-basic", component: BootstrapTable },
	
		/// Form
		{ url: "form-element", component: Element },
		{ url: "form-wizard", component: Wizard },
		{ url: "form-wizard", component: Wizard },
		{ url: "form-editor-summernote", component: SummerNote },
		{ url: "form-pickers", component: Pickers },
		{ url: "form-validation-jquery", component: jQueryValidation },

		/// Plugin

		{ url: "uc-select2", component: Select2 },
		{ url: "uc-noui-slider", component: MainNouiSlider },
		{ url: "uc-sweetalert", component: MainSweetAlert },
		{ url: "uc-toastr", component: Toastr },
		{ url: "map-jqvmap", component: JqvMap },
		{ url: "uc-lightgallery", component: Lightgallery },

		/// pages
		{ url: "widget-basic", component: Widget },
		{ url: "page-register", component: Registration },
		{ url: "page-lock-screen", component: LockScreen },
		{ url: "page-login", component: Login },
		{ url: "page-error-400", component: Error400 },
		{ url: "page-error-403", component: Error403 },
		{ url: "page-error-404", component: Error404 },
		{ url: "page-error-500", component: Error500 },
		{ url: "page-error-503", component: Error503 },
		{ url: "page-forgot-password", component: ForgotPassword },
   ];

   return (
		<Router basename="/">
			<div
            id={`${!pagePath ? "main-wrapper" : ""}`}
            className={`${!pagePath ? "show" : "mh100vh"}`}
         >
            {!pagePath && (
               <Nav
                  onClick={() => setActiveEvent(!activeEvent)} activeEvent={activeEvent}
                  onClick2={() => setActiveEvent(false)}
                  onClick3={() => setActiveEvent(true)}
               />
            )}
            
            <div
               className={` ${!path && activeEvent ? "rightside-event" : ""} ${
                  !pagePath ? "content-body" : ""
               }`}
            >
               <div className={`${!pagePath ? "container-fluid" : ""}`}>
                  <Routes>
                     {routes.map((data, i) => (
                        <Route
                           key={i}
                           exact
                           path={`/${data.url}`}
                        //    component={data.component}
                           element={<data.component />}
                        />
                     ))}
                  </Routes>
               </div>
            </div>
            {/* {!pagePath && <Footer />} */}
         </div>
		 <ScrollToTop />
		</Router>	
    );
};

export default Markup;
