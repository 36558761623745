import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../images/logo-full.png';
import axios from "axios";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import firebase_app from "../../firebase";
const auth = getAuth(firebase_app);

const Registrations = () => {
   const [registrationData, setRegistrationData] = useState({});
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [username, setUsername] = useState('');
   const [error, setError] = useState('');
   const navigate = useNavigate();

   useEffect(() => {
      const resolveLogin = async () => {

        
         if (!localStorage.getItem('userData')) { return; }

         const user = JSON.parse(localStorage.getItem('userData'));

         var response = await axios
         .get(`http://localhost:8090/api/auth/v1/validToken?token=${user.stsTokenManager.accessToken}`);
       
         if (!response.data) {
            navigate('/page-login'); 
            return;
         }

         navigate('/home');
      };
      resolveLogin();
   }, []);

   const submitHandler = async () => {
      setError('');
      if (!email || !password || !username) {
         setError('provide email and password');
         return;
      }
      try {
         var response = await createUserWithEmailAndPassword(auth, email, password);

         localStorage.setItem('userData', JSON.stringify(response.user));

         navigate('/home');

      } catch (error) {
         setError(error.response.data.message);
         console.error('Login error:', error);
      }
   };

   return (
      <div className="row justify-content-center  align-items-center h-100 h-80">
         <div className="col-md-6">
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <div className="text-center mb-3">
                           <Link to={"/"}><img src={logo} alt="" style={{ width: '250px' }} /></Link>
                           <div className="text-center mb-4 text-white"> Centralize Your Data and Workflows, Operate More Efficiently.  </div>

                        </div>
                        <h4 className="text-center mb-4 text-white">Sign up for your account</h4>
                        <form action="" onSubmit={(e) => e.preventDefault(submitHandler)}>
                           <div className="form-group">
                              <label className="mb-1 text-white"> <strong>Username</strong> </label>
                              <input type="text" className="form-control" placeholder="username" name="name" value={username} onChange={(e) => setUsername(e.target.value)} />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-white ">   <strong>Email</strong>  </label>
                              <input type="email" className="form-control" placeholder="hello@example.com" value={email} name="Email" onChange={(e) => setEmail(e.target.value)} />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-white">   <strong>Password</strong>  </label>
                              <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                           </div>
                           <div className="text-center mt-4">
                              <button type="submit" className="btn bg-white text-success btn-block" onClick={submitHandler} >  Sign Up  </button>
                           </div>
                        </form>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="new-account mt-3">
                           <p className="text-white">
                              Already have an account?{" "}
                              <Link className="text-white" to="/page-login">Sign in </Link>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Registrations;

