import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import { withResizeDetector } from "react-resize-detector";

const App = ({ width }) => {
   const body = document.querySelector("body");

   width >= 1300
      ? body.setAttribute("data-sidebar-style", "full")
      : width <= 1299 && width >= 767
      ? body.setAttribute("data-sidebar-style", "mini")
      : body.setAttribute("data-sidebar-style", "overlay");


   window.addEventListener("error", function (e) {
      console.error(e.message);
   });

   //handle weird resize error
   function debounce(func, wait, immediate) {
      let timeout;
      return function() {
         const context = this, args = arguments;
         const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
         };
         const callNow = immediate && !timeout;
         clearTimeout(timeout);
         timeout = setTimeout(later, wait);
         if (callNow) func.apply(context, args);
      };
   }

   const handleResize = debounce(() => {
      console.log("na me be this?")
   }, 200);

   // window.addEventListener('error', handleResize);

   window.onerror = function(message, source, lineno, colno, error) {
      if (message.includes("ResizeObserver loop limit exceeded") || 
         message.includes("ResizeObserver loop completed with undelivered notifications") ||
         message.length > 0) {
         console.warn("Handled ResizeObserver error nawa oo: ", message);
         return true; // Prevents the default handling of this error
      }
      // Additional global error handling logic can go here
      return false; // Normal error handling continues for other errors
   };

	return (
		<Fragment>
			<Markup />
		</Fragment>
	);
};

export default withResizeDetector(App, { debounce: 200 });
