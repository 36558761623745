// firebase.js

import { initializeApp, getApps } from "firebase/app";
import 'firebase/auth'; // Import additional Firebase services if needed

const firebaseConfig = {
    apiKey: "AIzaSyCqeAhLwITBJ1-OH17tyqFWCMF-ckpHBck",
    authDomain: "abiu-dashboard-beta.firebaseapp.com",
    projectId: "abiu-dashboard-beta",
    storageBucket: "abiu-dashboard-beta.appspot.com",
    messagingSenderId: "179144513005",
    appId: "1:179144513005:web:a8be438fcfe364542e03e4",
    measurementId: "G-54E8S5RHGX"
};


// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebase_app;
