import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import axios from "axios";

// import images 
import you from '../../../../images/users/9.png';
import abiu from '../../../../images/avatar/app-logo.png';



var initialchats = [
	// { message: "hello dear", timeStamp: "9:00 AM", senderId: "123" },
	// { message: "hello me", timeStamp: "10:00 AM", senderId: "124" },
	// { message: "hello free", timeStamp: "11:00 AM", senderId: "123" },
];

var dropDownOption = [
	{ value: "NERC Reliability Standards" },
	{ value: "EPA Renewable Fuel Standard (RFS)" },
	{ value: "FERC Interconnection Standards" },
	{ value: "OSHA Workplace Safety Regulations" },
	{ value: "IRS Investment Tax Credit (ITC)" },
	{ value: "State Renewable Portfolio Standards (RPS)" },
	{ value: "ISO/RTO Market Rules" },
	{ value: "FAA Obstruction Evaluation" },
	{ value: "EPA Spill Prevention, Control, and Countermeasure (SPCC) Plan" },
	{ value: "FWS Endangered Species Act (ESA) Compliance" },
]



const Messages = () => {
	const [messageText, setMessageText] = useState("");
	const [fileUploadState, setFileUploadState] = useState({ fileUploaded: false, uploadingFile: false });
	const [chats, setChats] = useState(initialchats);
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedOption, setSelectedOption] = useState('');
	const divRef = useRef(null);
	const sessionId = "demo-session";

	const selectStyle = {
		borderRadius: '10px', // Apply a border-radius of 10px
		border: '2px solid #acacad', // Apply a border with a custom color (red in this case)
		padding: '10px' // Add padding to ensure content doesn't touch the border
	};

	// Scroll to the bottom every time chats update
	useEffect(() => {
		console.log('Effect running', divRef.current);
		if (divRef.current) {
			const scrollElement = divRef.current._container || divRef.current;
			console.log('Scroll element:', scrollElement);
			scrollElement.scrollTop = scrollElement.scrollHeight;
		}
	}, [chats]);


	const handleUpload = () => {
		if (selectedFile) {
			const formData = new FormData();
			formData.append('imageFile', selectedFile);
			console.log('Uploading file...', formData);
			uploadFile(formData)
		} else {
			console.log('No file selected.');
		}
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setFileUploadState({ uploadingFile: false, fileUploaded: false });
	};


	const handleMessage = (e) => {
		setMessageText(e.target.value)
	}

	const sendMessage = () => {

		var newChat = [
			...chats,
			{ message: messageText, timeStamp: "9:00 AM", senderId: "124" },
			{ message: "loading..." },
		];

		setChats(newChat)
		setMessageText("")

		setTimeout(() => {
			fetchResponse(messageText, newChat)
		}, 2000);

	}

	const validateCompliance = () => {
		const messageText = "Is the document in context compliant with " + selectedOption;
		var newChat = [
			...chats,
			{ message: messageText, timeStamp: "9:00 AM", senderId: "124" },
			{ message: "loading..." },
		];

		setChats(newChat)
		setMessageText("")

		setTimeout(() => {
			fetchResponse(messageText, newChat)
		}, 2000);

	}

	const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const uploadFile = async (formData) => {
		setFileUploadState({ ...fileUploadState, uploadingFile: true });

		var response = await axios
			.post(`https://earth-api-v2.onrender.com/api/chats/v1/file-upload`, formData);

		if (response.data) {
			console.log(response.data);
			setSelectedFile(null);
			setFileUploadState({ uploadingFile: false, fileUploaded: true });
		}

	}

	const fetchResponse = async (messageText, chats) => {

		var response = await axios
			.post(`https://earth-api-v2.onrender.com/api/chats/v1/chat`, { messageText: messageText });


		if (response.data) {
			var lastChat = chats[chats.length - 1];
			var n = [
				...chats.filter(chat => chat.senderId != null),
				{ ...lastChat, message: response.data.message, timeStamp: response.data.timeStamp, senderId: "123" }
			];
			setChats(n)
		}

	}
	return (
		<Fragment>
			{/* <!-- Add Order --> */}
			<div className="modal fade" id="addOrderModalside">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Create Project</h5>
							<button type="button" className="close" data-dismiss="modal"><span>&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form>
								<div className="form-group">
									<label className="text-black font-w500">Project Name</label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<label className="text-black font-w500">Deadline</label>
									<input type="date" className="form-control" />
								</div>
								<div className="form-group">
									<label className="text-black font-w500">Client Name</label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<button type="button" className="btn btn-primary">CREATE</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card  message-bx chat-box">
								<div className="card-header border-0 shadow-sm row">
									<div className="d-flex mr-auto align-items-center">
										<div className="image-bx mr-sm-4 mr-2">
											<img src={abiu} alt="" className="rounded-circle img-1" />
											<span className="active"></span>
										</div>
										{
											fileUploadState.fileUploaded ?
												<div className="text-black font-w600 mb-sm-1 mb-0 title-nm"> File Uploaded </div> :
												<div>
													<h5 className="text-black font-w600 mb-sm-1 mb-0 title-nm">
														{selectedFile?.name ? "Selected File" : ""}
													</h5>
													<span>{selectedFile?.name}</span>
												</div>
										}
									</div>
									<div className='row'>
										<select value={selectedOption} onChange={handleSelectChange} className="br-20" style={selectStyle}>
											{
												dropDownOption.map((option, index) => <option value={option.value}>{option.value}</option>)
											}

										</select>
										<div className='mr-2 ml-2'>
											<button type="button" className="btn btn-primary light rounded" onClick={validateCompliance}>Validate</button>
										</div>
									</div>
								</div>
								<PerfectScrollbar className="card-body dlab-scroll height640" id="chartBox" ref={divRef}>

									{chats.map((chat, index) => (

										<>
											{chat.senderId == null ?
												<div className="media mb-4  justify-content-start align-items-start">
													<div className="image-bx mr-sm-4 mr-2">
														<img src={abiu} alt="" className="rounded-circle img-1" />
														<span className="active"></span>
													</div>
													<div className="message-received">
														<p className='mt-2'>
															{chat.message}
														</p>
														<span className="msg_time">{chat.timeStamp}</span>
													</div>
												</div> : chat.senderId === "123" &&

												<div className="media mb-4  justify-content-start align-items-start">
													<div className="image-bx mr-sm-4 mr-2">
														<img src={abiu} alt="" className="rounded-circle img-1" />
														<span className="active"></span>
													</div>
													<div className="message-received">
														<p className="mb-1">
															{chat.message}
														</p>
														<span className="fs-12">{chat.timeStamp}</span>
													</div>
												</div>
											}
											{
												chat.senderId === "124" &&

												<div className="media mb-4 justify-content-end align-items-end">
													<div className="message-sent">
														<p className="mb-1">
															{chat.message}
														</p>
														<span className="fs-12">{chat.timeStamp}</span>
													</div>
													<div className="image-bx ml-sm-4 ml-2 mb-4">
														<img src={you} alt="" className="rounded-circle img-1" />
														<span className="active"></span>
													</div>
												</div>
											}
										</>

									))}

								</PerfectScrollbar>
								<div className="card-footer border-0 type-massage">
									<div className="input-group">
										<textarea className="form-control" placeholder="Type message..."
											value={messageText}
											onChange={(e) => handleMessage(e)}
										></textarea>
										<div className="input-group-append">
											{selectedFile == null ? <label htmlFor="file-input" className="btn shadow-none pr-0 mt-2">
												<i className="las la-paperclip scale5"></i>
											</label> :
												fileUploadState.uploadingFile ? <div className="btn shadow-none pr-0 mt-2">Uploading file...</div> :
													<button onClick={handleUpload} className="btn shadow-none pr-0">Upload</button>
											}
											<input type="file" accept="application/pdf" id='file-input'
												onChange={handleFileChange} style={{ display: 'none' }} />
											<button type="button" className="btn shadow-none"><i className="las la-image scale5"></i></button>
											<button type="button" className="btn btn-primary light rounded" onClick={sendMessage}>SEND</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</Fragment>
	)
};

export default Messages;
